<template>
  <div>
    <v-breadcrumbs :items="crumbs"></v-breadcrumbs>
    <base-loader :isLoading="isLoading"></base-loader>
    <v-row>
      <v-col md="12">
        <v-card>
          <v-card-text>
            <h1 class="mb-5">
              {{ `${isEdit ? "Edit" : "New"} Employee-`
              }}<span class="primary--text"
                ><strong>{{
                  `${user.firstName} ${user.lastName}`
                }}</strong></span
              >
            </h1>
            <v-form ref="userForm" @submit.prevent="save()">
              <v-row>
                <v-switch
                  class="mb-1 ml-2"
                  v-model="user.profileStatus"
                  label="Inactive/Active"
                />
              </v-row>
              <v-row>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <h2>Personal Details</h2>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col md="6">
                          <v-text-field
                            label="First Name"
                            v-model="user.firstName"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col md="6">
                          <v-text-field
                            label="Last Name"
                            v-model="user.lastName"
                            outlined
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col md="6">
                          <p class="display-2">
                            <strong>Assigned Title </strong>
                            {{ this.user.title || "Not specified yet" }}
                          </p>
                          <base-title-picker
                            color="primary"
                            :label="'Title'"
                            :chosen-title.sync="user.title"
                            :initial="user.title"
                            v-model="user.title"
                            outlined
                          />
                        </v-col>

                        <v-col md="6">
                          <p class="display-2">
                            <strong>Assigned rel. </strong>
                            {{ this.user.relationship || "Not specified yet" }}
                          </p>
                          <base-relationship-status-picker
                            :label="'Change Relationship Status'"
                            :initial="user.relationship"
                            :chosen-relationship-status.sync="user.relationship"
                            outlined
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col md="6">
                          <p class="display-2">
                            <strong>Assigned gender: </strong>
                            {{ this.user.gender || "Not specified yet" }}
                          </p>
                          <base-gender-picker
                            :label="'Change Gender'"
                            :initial="user.gender"
                            :chosen-gender.sync="user.gender"
                            outlined
                          />
                        </v-col>

                        <v-col md="6">
                          <p class="display-2">
                            <strong>Assigned race: </strong>
                            {{ this.user.race || "Not specified yet" }}
                          </p>
                          <base-race-picker
                            :label="'Change Race'"
                            :initial="user.race"
                            :chosen-race.sync="user.race"
                            outlined
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col md="12">
                          <p class="display-2">
                            <strong>Assigned Language: </strong>
                            {{ this.user.language || "Not specified yet" }}
                          </p>
                          <base-language-picker
                            :label="'Choose Language'"
                            :initial="user.language"
                            :chosen-language.sync="user.language"
                            outlined
                          />
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <h2>Contact Details</h2>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col md="6">
                          <v-text-field
                            label="Mobile"
                            v-model="user.telNumber"
                            prefix="(+27)"
                            outlined
                          ></v-text-field
                        ></v-col>
                        <v-col md="6">
                          <v-text-field
                            label="Alternative Number"
                            v-model="user.altNumber"
                            prefix="(+27)"
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col md="6"
                          ><v-text-field
                            label="Email"
                            v-model="user.email"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col md="6"> </v-col>
                        <v-col md="6">
                          <v-text-field
                            label="Fax Number"
                            v-model="user.faxNumber"
                            prefix="(+27)"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col md="6">
                          <v-text-field
                            label="Postal Code"
                            v-model="user.postalCode"
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col md="12">
                          <v-text-field
                            label="Region"
                            v-model="user.region"
                            placeholder="Gauteng, JHB etc"
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-textarea
                            v-model="user.bio"
                            outlined
                            counter
                            label="Notes on client"
                            bio
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="mr-4" cols="12" sm="12" md="12">
                          <h2>Experience with user Rating</h2>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-rating
                            v-model="user.rating"
                            background-color="purple lighten-3"
                            half-increments
                            hover
                            length="5"
                            size="40"
                          ></v-rating>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <h2>Birth Details</h2></v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col md="6">
                          <v-col>
                            <div>
                              <!-- <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="user.dob"
                                    label="Birthday date"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    outlined
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="user.dob"
                                  outlined
                                  :max="
                                    new Date(
                                      Date.now() -
                                        new Date().getTimezoneOffset() * 60000
                                    )
                                      .toISOString()
                                      .substr(0, 10)
                                  "
                                  min="1900-01-01"
                                  @change="saveDob"
                                ></v-date-picker>
                              </v-menu> -->
                            </div>
                          </v-col>
                        </v-col>
                        <v-col md="6">
                          <v-text-field
                            label="Age"
                            class="mt-3"
                            v-model="user.age"
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col md="12">
                          <v-text-field
                            label="ID Number"
                            v-model="user.idNumber"
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-col md="12">
                        <v-text-field
                          label="Passport Number"
                          v-model="user.passport"
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <h2>Address details</h2>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12" md="12" sm="12">
                          <v-textarea
                            label="Address"
                            outlined
                            counter
                            v-model="user.fullAddress"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="12" sm="12">
                          <v-textarea
                            label="Postal Address"
                            outlined
                            counter
                            v-model="user.postalAddress"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col md="6">
                          <v-text-field
                            label="Postal Code"
                            v-model="user.postalCode"
                            outlined
                          ></v-text-field>
                        </v-col>

                        <v-col md="6">
                          <v-text-field
                            label="Region"
                            v-model="user.region"
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>

              <v-row>
                <v-col md="12">
                  <p class="mb-4">
                    <strong>Assigned Role: </strong>
                  </p>
                  <v-select
                    v-model="user.role"
                    v-if="roles && roles.roles"
                    item-text="name"
                    label="Role"
                    class="mt-12"
                    :items="roles.roles"
                    outlined
                  ></v-select>
                </v-col>

                <v-col md="12">
                  <base-user-edit-picker
                    :label="'Assigned Employer'"
                    :initial="user.employer"
                    :chosen-user.sync="user.employer"
                    :role="'employer'"
                    outlined
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col md="6">
                  <p class="display-2">
                    <strong>Last Updated By: </strong>
                    {{ this.user.updatedByName || "Not specified yet" }}
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <base-password-reset-modal />
                <v-spacer></v-spacer>
                <v-col class="text-right">
                  <v-btn type="submit" color="success" class="text-align-right">
                    <h1>Save</h1>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- <v-col md="6" v-if="user.id">
        <v-card class="mt-8">
          <v-card-text>
            <v-card>
              <h1>Fica Documents</h1>
              <base-user-fica-products
                :userId="user.id"
                :consultantId="user.consultant"
              />
            </v-card>
          </v-card-text>
        </v-card>
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import { User } from "@/models/User";

export default {
  components: {},
  name: "EditUser",
  data() {
    return {
      tab: 0,
      tabs: ["View Timeline", "Edit Events"],
      isEdit: false,
      isLoading: false,

      menu: false,
      date: null,
      search: "",
      dialog3: false,
      totalRecords: 0,
      id: "",
      crumbs: [
        {
          text: "Employees",
          disabled: false,
          href: "/admin/users",
        },
        {
          text: "Employee Detail",
          disabled: false,
          href: `/admin/user/${this.$route.params.id}`,
        },
        {
          text: `${this.isEdit ? "Edit" : "New"} Employee`,
          disabled: true,
        },
      ],

      me: "",
      headers: [
        {
          text: "Explanation",
          align: "start",
          sortable: false,
          value: "explanation",
        },

        { text: "Created Date", value: "createdAt", align: "left" },
        { text: "Actions", align: "left", sortable: false, value: "actions" },
      ],
    };
  },
  created() {
    this.isEdit = this.$route.params.id != 0;
    this.isLoading = this.isEdit ? true : false;
  },
  computed: {},

  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,

    user: {
      query: gql`
        query User($id: ID!) {
          user(id: $id) {
            id
            firstName
            lastName
            title
            relationship
            gender
            race
            telNumber
            altNumber
            email
            # businessEmail
            faxNumber
            bio
            rating
            # age
            # dob
            idNumber
            passport
            fullAddress
            language
            postalAddress
            industry
            role
            referral
            # branch
            # branchName
            consultant
            consultantName
            postalCode
            region
            mailingPref
            referralNumber
            updatedBy
            updatedByName
            profileStatus
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return !this.isEdit;
      },
      variables() {
        const self = this;
        return {
          id: self.$route.params.id,
        };
      },
      result(response, key) {
        this.isLoading = false;
        this.user.id = this.user.id;
        this.user.firstName = this.user.firstName;
        this.user.lastName = this.user.lastName;
        this.user.title = this.user.title;
        this.user.relationship = this.user.relationship;
        this.user.gender = this.user.gender;
        this.user.race = this.user.race;
        this.user.telNumber = this.user.telNumber;
        this.user.altNumber = this.user.altNumber;
        this.user.email = this.user.email;
        // this.user.businessEmail = this.user.businessEmail;
        this.user.faxNumber = this.user.faxNumber;
        this.user.rating = this.user.rating;
        this.user.bio = this.user.bio;
        // this.user.dob = this.user.dob;
        // this.user.age = this.user.age;
        this.user.idNumber = this.user.idNumber;
        this.user.passport = this.user.passport;
        this.user.fullAddress = this.user.fullAddress;
        this.user.postalAddress = this.user.postalAddress;
        this.user.language = this.user.language;
        this.user.industry = this.user.industry;
        this.user.role = this.user.role;
        this.user.referral = this.user.referral;
        this.user.referralNumber = this.user.referralNumber;
        this.user.consultant = this.user.consultant;
        this.user.consultantName = this.user.consultantName;
        // this.user.branch = this.user.branch;
        // this.user.branchName = this.user.branchName;
        this.user.postalCode = this.user.postalCode;
        this.user.region = this.user.region;
        this.user.mailingPref = this.user.mailingPref;
        this.user.updatedBy = this.user.updatedBy;
        this.user.updatedByName = this.user.updatedByName;
        this.user.profileStatus = this.user.profileStatus;
      },
    },

    roles: {
      query: gql`
        query {
          __type(name: "Role") {
            roles: enumValues {
              name
            }
          }
        }
      `,
      update: (data) => data.__type,
    },
    eventStatuses: {
      query: gql`
        query {
          __type(name: "EventStatus") {
            eventStatuses: enumValues {
              name
            }
          }
        }
      `,
      update: (data) => data.__type,
    },
  },
  methods: {
    // saveDob(date) {
    //   this.$refs.menu.save(date);
    // },
    async save() {
      this.isLoading = true;
      const self = this;
      if (!self.$refs.userForm.validate()) {
        self.$swal("Error", "Validation failed", "error");

        self.isLoading = false;
        return;
      }
      if (self.isEdit) {
        await this.updateUser()
          .then(() => {
            this.$swal("Success!", "Employee was updated", "success");
            window.location.href = `/admin/user/${self.$route.params.id}/edit`;
          })
          .catch((e) => {
            this.$swal(
              "Error!",
              "something went wrong when trying to update the Employee, check data and try again later!",
              "error"
            );
          });
      } else {
        await this.createUser()
          .then(() => {
            this.$swal("Success!", "Employee was created", "success");
            window.location.href = `/admin/user/${self.user.id}/edit`;
          })
          .catch((e) => {
            this.$swal(
              "Error!",
              `Something went wrong when creating the Employee, check data and try again later!`,
              "error"
            );
          });
      }
      this.isLoading = false;
    },
    async updateUser() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation updateUser($user: UserUpdateInput!) {
              updateUser(user: $user) {
                id
                updated
              }
            }
          `,
          variables: {
            user: {
              id: self.$route.params.id,
              firstName: self.user.firstName,
              lastName: self.user.lastName,
              title: self.user.title,
              relationship: self.user.relationship,
              language: self.user.language,
              gender: self.user.gender,
              race: self.user.race,
              telNumber: self.user.telNumber,
              altNumber: self.user.altNumber,
              email: self.user.email,
              // businessEmail: self.user.businessEmail,
              faxNumber: self.user.faxNumber,
              region: self.user.region,
              postalCode: self.user.postalCode,
              bio: self.user.bio,
              rating: self.user.rating,
              // dob: self.user.dob,
              idNumber: self.user.idNumber,
              passport: self.user.passport,
              fullAddress: self.user.fullAddress,
              postalAddress: self.user.postalAddress,
              industry: self.user.industry,
              role: self.user.role,
              referral: self.user.referral,
              referralNumber: self.user.referralNumber,
              // branch: self.user.branch,
              consultant: self.user.consultant,
              mailingPref: self.user.mailingPref,
              profileStatus: self.user.profileStatus,
              updatedBy: self.me.id,
            },
          },
        });
        if (!response) {
          throw "Something went wrong when creating the user!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
      } catch (e) {
        throw e.message;
      }
    },
    async createUser() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation createUser($user: UserCreateInput!) {
              createUser(user: $user) {
                id
              }
            }
          `,
          variables: {
            user: {
              firstName: self.user.firstName,
              lastName: self.user.lastName,
              title: self.user.title,
              relationship: self.user.relationship,
              language: self.user.language,
              gender: self.user.gender,
              race: self.user.race,
              telNumber: self.user.telNumber,
              altNumber: self.user.altNumber,
              email: self.user.email,
              // businessEmail: self.user.businessEmail,
              faxNumber: self.user.faxNumber,
              bio: self.user.bio,
              rating: self.user.rating,
              // dob: self.user.dob,
              idNumber: self.user.idNumber,
              passport: self.user.passport,
              fullAddress: self.user.fullAddress,
              postalAddress: self.user.postalAddress,
              industry: self.user.industry,
              role: self.user.role,
              referral: self.user.referral,
              referralNumber: self.user.referralNumber,
              // branch: self.user.branch,
              consultant: self.user.consultant,
              postalCode: self.user.postalCode,
              region: self.user.region,
              mailingPref: self.user.mailingPref,
              profileStatus: self.user.profileStatus,
            },
          },
        });
        if (!response) {
          throw "Something went wrong when creating the Employee!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
        if (response && response.data.createUser) {
          self.user.id = response?.data?.createUser?.id;
        }
      } catch (e) {
        throw e.message;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
